import React from "react"
import HeroImage from "./components/HeroImage"
import BrandImage from './components/BrandImage';

export default function GliEroi() {
  return (
    <section className="section-glieroi">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-four-fifths">
            <h1>GLI<br />EROI</h1>
            <div className="columns has-text-centered">
              <div className="column">
                <HeroImage fileName="andrea-pietrini.png" alt="Davide Dattoli" />
                <p className="mt-1"><b>Andrea Pietrini</b></p>
                <p>CEO YOURgroup</p>
                <p><i>L’imprenditore visionario</i></p>
              </div>
              <div className="column">
                <HeroImage fileName="davide-dattoli.png" alt="Davide Dattoli" />
                <p className="mt-1"><b>Davide Dattoli</b></p>
                <p>Co-Founder e CEO Talent Garden</p>
                <p><i>L’esploratore digitale</i></p>
              </div>
              <div className="column">
               <HeroImage fileName="fabio-zecchini.png" alt="Fabio Zecchini" />
                <p className="mt-1"><b>Fabio Zecchini</b></p>
                <p>Co-Founder Musement</p>
                <p><i>Il Digital magician</i></p>
              </div>
            </div>
            <div className="columns has-text-centered">
              <div className="column">
                <HeroImage fileName="gabriella-scapicchio.png" alt="Gabriella Scapicchio" />
                <p className="mt-1"><b>Gabriella Scapicchio</b></p>
                <p>Sindaco Le Village by CA Milano</p>
                <p><i>L’innovation Hero</i></p>
              </div>
              <div className="column">
                <HeroImage fileName="giulia-manca.png" alt="Giulia Manca" />
                <p className="mt-1"><b>Giulia Manca</b></p>
                <p>Naming expert</p>
                <p><i>La brand activator</i></p>
              </div>
              <div className="column">
               <HeroImage fileName="matteo-concas.png" alt="Matteo Concas" />
                <p className="mt-1"><b>Matteo Concas</b></p>
                <p>Head of Global Financial Solutions Enel X</p>
                <p><i>Il Growth Hero</i></p>
              </div>
            </div>
            <div className="columns has-text-centered">
              <div className="column">
                <HeroImage fileName="natale-miniero.png" alt="Natale Miniero" />
                <p className="mt-1"><b>Natale Miniero</b></p>
                <p> Direttore Marketing Electrolux Professional Italia</p>
                <p><i>Il manager dei valori</i></p>
              </div>
              <div className="column">
                <HeroImage fileName="raffaele-mauro.png" alt="Raffaele Mauro" />
                <p className="mt-1"><b>Raffaele Mauro</b></p>
                <p>Managing Director Endeavor Italia</p>
                <p><i>L’impact hero</i></p>
              </div>
              <div className="column">
                <HeroImage fileName="sergio-azzolari.png" alt="Sergio Azzolari" />
                <p className="mt-1"><b>Sergio Azzolari</b></p>
                <p>General Manager Hogan e Fay</p>
                <p><i>Il general manager</i></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column is-four-fifths has-text-centered mt-5">
          <h3>Casi di studio</h3>
          <div className="columns has-text-centered mt-2">
            <div className="column">
              <BrandImage fileName="Boolean.png" alt="Boolean" />
            </div>
            <div className="column">
              <BrandImage fileName="Borsa_del_credito.png" alt="Boolean" />
            </div>
            <div className="column">
              <BrandImage fileName="Endeavor.png" alt="Boolean" />
            </div>
            <div className="column">
              <BrandImage fileName="Fay.png" alt="Boolean" />
            </div>
            <div className="column">
              <BrandImage fileName="HOGAN.png" alt="Boolean" />
            </div>
            <div className="column">
              <BrandImage fileName="ifis.png" alt="Boolean" />
            </div>
          </div>
          <div className="columns has-text-centered">
            <div className="column">
              <BrandImage fileName="Istituto_Auxologico_Italiano.png" alt="Boolean" />
            </div>
            <div className="column">
              <BrandImage fileName="Le_Village.png" alt="Boolean" />
            </div>
            <div className="column">
              <BrandImage fileName="Legambiente_Lombardia.png" alt="Boolean" />
            </div>
            <div className="column">
              <BrandImage fileName="MamaClean.png" alt="Boolean" />
            </div>
            <div className="column">
              <BrandImage fileName="Musement.png" alt="Boolean" />
            </div>
            <div className="column">
              <BrandImage fileName="Neoruralehub.png" alt="Boolean" />
            </div>
          </div>
          <div className="columns has-text-centered mb-5">
            <div className="column">
              <BrandImage fileName="Talent_Garden.png" alt="Boolean" />
            </div>
            <div className="column">
              <BrandImage fileName="Tenortho.png" alt="Boolean" />
            </div>
            <div className="column">
              <BrandImage fileName="TheRockTrading.png" alt="Boolean" />
            </div>
            <div className="column">
              <BrandImage fileName="yourGROUP.png" alt="Boolean" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
